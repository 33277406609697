.audio-meter {
    width: 300px;
    height: 10px;
    display: flex;
    gap: 2px;
    border-radius: 5px;
    overflow: hidden;
    padding: '5px 0';
}

.segment {
    flex: 1;
    background-color: #ccc;
    border-radius: 5px;
    height: 100%;
}

.segment.filled {
    background-color: #4caf50;
}
